import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const GalleryComponent = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="py-[50px]">
        {/* <div className="gallery-content w-4/5 mx-auto">
          <Gallery>
            {rpdata?.gallery?.map((item, index) => (
              <Item
                key={index}
                original={item}
                thumbnail={item}
                width="1024"
                height="550"
                padding="10px"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={item} alt="Not Found" />
                )}
              </Item>
            ))}
          </Gallery>
        </div> */}
        <h2 className="text-center pt-[100px]">Our Recent Projects</h2>
        <div className="gallery-content w-4/5 mx-auto">
          <Gallery>
            {rpdata?.gallery?.map((item, index) => (
              <Item
                key={index}
                original={item}
                thumbnail={item}
                width="1024"
                height="550"
                padding="10px"
              >
                {({ ref, open }) => (
                  <img ref={ref} onClick={open} src={item} alt="Not Found" />
                )}
              </Item>
            ))}
          </Gallery>
        </div>
      </div>
    </>
  );
};

export default GalleryComponent;
